import { Form, Input, Modal } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import {
  InputPasswordStyled,
  InputStyled,
  MaskedInputStyled,
  SubmitButtonStyled,
} from "./loginForm";
import { PHONE } from "../../../constants/AdditionalInfo";
import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { LabelStyled } from "../../car/components/addCar/form";
import { AuthActions } from "../redux/auth.slice";
import { ResetPasswordThunk } from "../redux/auth.thunks";

const TitleStyled = styled.div`
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  margin-bottom: 30px;
`;

const FormItemRowStyled = styled(Form.Item)`
  margin-bottom: 0px;
  & .ant-form-item-control-input-content {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  & .ant-form-item {
    flex-grow: 1;
    margin-bottom: 8px;
  }
`;

const TimerTextStyled = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  margin-bottom: 24px;
`;

const TimerTextTimeStyled = styled.span`
  color: rgb(116, 99, 232);
`;

const CancelButtonStyled = styled(SubmitButtonStyled)`
  background: rgb(255, 255, 255);
  color: rgb(61, 61, 61);
  border: 1px solid rgb(219, 219, 219);
  margin-top: 16px;
`;

export const ResetForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const isPending = useAppSelector((state) => state.auth.isPending);
  const isOpen = useAppSelector((state) => state.auth.isResetOpen);
  const resetErrors = useAppSelector((state) => state.auth.resetErrors);
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [phone, setPhone] = useState("");
  const [isTimer, setIsTimer] = useState(false);

  const onFinish = (values: { phone: string; password: string; code: string }) => {
    dispatch(
      ResetPasswordThunk({
        userData: {
          password: values.password,
          phone: values.phone.replace(/\s/g, "").replace("+", ""),
          code: values.code,
        },
      }),
    );
  };

  useEffect(() => {
    if (resetErrors) {
      for (const er in resetErrors) {
        form.setFields([
          {
            name: er,
            errors: [t(resetErrors[er])],
          },
        ]);
      }
    }
  }, [resetErrors, form, t]);

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 17) {
      form.setFieldsValue({ phone: phone });
      return;
    }

    setPhone(e.target.value);
    form.setFieldsValue({ phone: e.target.value });
  };

  const handleSendCode = async () => {
    try {
      const response = await axios.post("auth/forgot-password-request", {
        phone: phone.replace(/\s/g, "").replace("+", ""),
      });
      console.log(response.data);
      setIsCodeSent(true);
      setIsTimer(true);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.phone) {
          form.setFields([
            {
              name: PHONE,
              errors: [t(error.response.data.phone)],
            },
          ]);
        }
      }
    }
  };

  const handleIsTimer = (value: boolean) => {
    setIsTimer(value);
    setIsCodeSent(false);
  };

  const handleCancel = () => {
    dispatch(AuthActions.closeReset());
  };
  return (
    <Modal open={isOpen} footer={null} onCancel={handleCancel} destroyOnClose>
      <TitleStyled>{t("auth.reset.label")}</TitleStyled>
      <Form
        name="reset"
        layout="vertical"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        disabled={isPending}
        form={form}
      >
        <FormItemRowStyled label={<LabelStyled>{t("auth.phone.label")}</LabelStyled>}>
          <Form.Item
            name={PHONE}
            validateTrigger="onBlur"
            rules={[
              { required: true, message: t("auth.phone.error") },
              { max: 17, message: t("auth.phone.error-format") },
              { min: 12, message: t("auth.phone.error-min-length", { length: 12 }) },
            ]}
          >
            <MaskedInputStyled
              mask="+380 99 999 99 99"
              value={phone}
              alwaysShowMask={false}
              onChange={handleChangePhone}
              placeholder="+380  "
              maskChar=""
            >
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                (props) => <Input {...props} />
              }
            </MaskedInputStyled>
          </Form.Item>
          <SubmitButtonStyled
            type="primary"
            onClick={handleSendCode}
            disabled={!phone || phone.length < 12 || isTimer}
          >
            {t("auth.reset.send-code")}
          </SubmitButtonStyled>
        </FormItemRowStyled>
        {isCodeSent && (
          <TimerTextStyled>
            {t("auth.reset.timer")}
            <TimerTextTimeStyled>
              <Timer setIsTimer={handleIsTimer} />
            </TimerTextTimeStyled>{" "}
            {t("auth.reset.seconds")}
          </TimerTextStyled>
        )}
        <Form.Item
          label={<LabelStyled>{t("auth.code.label")}</LabelStyled>}
          name="code"
          rules={[{ required: true, message: t("auth.code.error") }]}
        >
          <InputStyled />
        </Form.Item>

        <Form.Item
          label={<LabelStyled>{t("auth.password.label")}</LabelStyled>}
          name="password"
          rules={[
            { required: true, message: t("auth.password.error") },
            { min: 6, message: t("auth.password.error-length", { length: 6 }) },
            {
              pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
              message: t("auth.password.error-format"),
            },
          ]}
        >
          <InputPasswordStyled />
        </Form.Item>
        <Form.Item
          label={<LabelStyled>{t("auth.password.repeat")}</LabelStyled>}
          name="password-repeat"
          rules={[
            { required: true, message: t("auth.phone.error") },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("The new password that you entered do not match!"));
              },
            }),
          ]}
        >
          <InputPasswordStyled />
        </Form.Item>

        <Form.Item>
          <SubmitButtonStyled block type="primary" htmlType="submit">
            {t("auth.recover.label")}
          </SubmitButtonStyled>
          <CancelButtonStyled block type="primary" onClick={handleCancel}>
            {t("auth.reset.cancel")}
          </CancelButtonStyled>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Timer: React.FC<{ setIsTimer: (value: boolean) => void }> = ({ setIsTimer }) => {
  const [time, setTime] = useState(120);
  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime === 1) {
          setTimeout(() => {
            setIsTimer(false);
          });

          clearInterval(interval);
          return 120;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <>{time}</>;
};
