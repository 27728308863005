import { Button, InputNumber, Select } from "antd";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { useAppDispatch, useAppSelector } from "../../../helpers/store.hook";
import { AdditionalInfoPyaload } from "../../car/CarTypes";
import { CarGetAdditionalInfoThunk } from "../../car/redux/car.thunks";
import { YearPickerComp } from "../../filters/components/years";
import {
  Brands,
  CarModel,
  FromToEnum,
  Modification,
  SelectedGeneration,
} from "../../filters/filters.types";
import { FiltersActions } from "../../filters/redux/filters.slice";
import { getGenerationsThunk, getModelsThunk } from "../../filters/redux/filters.thunks";
import { createGenSearch } from "../utils/createSearch";

export interface SelectProps {
  onSelect?: (value: string, options: any) => void;
  onChange?: (value: string | string[]) => void;
  dropdownRender?: (originNode: ReactNode) => ReactNode;
}

interface SelectMultyProps extends Omit<SelectProps, "onChange"> {
  onChange?: (value: string[]) => void;
}
const SelectStyled = styled(Select)<SelectProps>`
  width: 100%;
  height: 40px !important;

  border-radius: 8px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  & .ant-select-selector {
    border: 1px solid rgb(219, 219, 219);
  }
`;

const SelectWrapperStyled = styled.div`
  margin-bottom: 14px;
`;
const SelectTitle = styled.div`
  color: rgb(52, 51, 57);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  margin-bottom: 8px;
`;
const SelectStyledMulty = styled(SelectStyled)<SelectMultyProps>``;

const FromToWrapperStyled = styled.div`
  display: flex;
  gap: 16px;
`;

const YearWrapperStyled = styled.div`
  height: 40px;
  & > div {
    height: 100%;
    & .ant-select-selector {
      border-radius: 8px !important;
    }
  }
`;

const ContainerStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  background: rgb(255, 255, 255);
`;

const InputNumberStyled = styled(InputNumber)<{ onChange?: (value: string) => void }>`
  width: 100%;
  height: 40px !important;
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  font-size: 16px;

  & .ant-input-number-input-wrap {
    height: 100%;
    display: flex;
  }

  & input {
    color: rgb(46, 46, 46);
    font-family: Roboto !important;

    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
  }
`;

export function debounce(func: any, delay: number) {
  // eslint-disable-next-line no-undef
  let timeoutId: NodeJS.Timeout;
  return function (this: any, ...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export const SearchFilter = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(CarGetAdditionalInfoThunk());
  }, [dispatch]);
  const brands = useAppSelector((state) => state.filters.brands);
  const models = useAppSelector((state) => state.filters.models);
  const selectedBrand = useAppSelector((state) => state.filters.selectedData?.brand);
  const selectedModel = useAppSelector((state) => state.filters.selectedData?.model);

  const bodyTypes = useAppSelector((state) => state.car.bodyType);
  const fuels = useAppSelector((state) => state.car.fuel);
  const driveTypes = useAppSelector((state) => state.car.driveType);
  const colors = useAppSelector((state) => state.car.color);
  const gears = useAppSelector((state) => state.car.gear);

  const selectedBodyType = useAppSelector((state) => state.filters.selectedData?.bodyType);
  const selectedFuel = useAppSelector((state) => state.filters.selectedData?.fuel);
  const selectedDriveType = useAppSelector((state) => state.filters.selectedData?.driveType);
  const selectedColor = useAppSelector((state) => state.filters.selectedData?.color);
  const selectedGear = useAppSelector((state) => state.filters.selectedData?.gear);
  const selectedPrice = useAppSelector((state) => state.filters.selectedData.price);
  const selectedPower = useAppSelector((state) => state.filters.selectedData.power);
  const selectedMileage = useAppSelector((state) => state.filters.selectedData.mileage);
  const selectedUseInfo = useAppSelector((state) => state.filters.selectedData.useInfo);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const manageSearch = useCallback(
    debounce((key: string, value: string) => {
      setSearchParams((prev) => {
        prev.set(key, value);
        return prev;
      });
    }, 800),
    [],
  );

  const manageAdvanceSearch = useCallback(
    (key: string, value: string[]) => {
      const nextSearch: { [key: string]: string[] } = {};

      searchParams.forEach((value, key) => {
        if (nextSearch[key]) {
          nextSearch[key] = [...nextSearch[key], value];
        } else {
          nextSearch[key] = [value];
        }
      });

      nextSearch[key] = value;

      setSearchParams(createSearchParams(nextSearch).toString());
    },
    [searchParams, setSearchParams],
  );

  const handleBrand = (value: string, options: Brands) => {
    if (selectedBrand?.name !== value) {
      dispatch(
        getModelsThunk({
          ...options,
          name: value,
        }),
      );
      manageSearch("brand", value);
    }
  };

  const handleModel = (value: string, options: CarModel & { isParent: boolean }) => {
    console.log("options", options);
    if (options.isParent) {
      dispatch(FiltersActions.setModel(options));
    } else {
      dispatch(getGenerationsThunk(options));
    }
    manageSearch("model", options.value || "");
  };

  const handleChangePrice = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(FiltersActions.setPrice({ type, value: (value as unknown as number) || undefined }));

      manageSearch(`price${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleChangePower = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(
        FiltersActions.setPowers({ type, value: (value as unknown as number) || undefined }),
      );

      manageSearch(`power${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleChangeMilage = (type: FromToEnum) => (value: string | null) => {
    const reg = /^-?\d*(\.\d*)?$/;
    if (reg.test(value as string) || value === "" || value === null) {
      dispatch(
        FiltersActions.setMileage({ type, value: (value as unknown as number) || undefined }),
      );

      manageSearch(`mileage${type.slice(0, 1).toUpperCase()}${type.slice(1)}`, value || "");
    }
  };

  const handleBodyType = (value: string[]) => {
    dispatch(FiltersActions.setBodyTypes(value));
    manageAdvanceSearch("bodyType", value);
  };

  const hangleFuel = (value: string[]) => {
    dispatch(FiltersActions.setFuels(value));
    manageAdvanceSearch("fuel", value);
  };
  const hangleDriveType = (value: string[]) => {
    dispatch(FiltersActions.setDriveTypes(value));
    manageAdvanceSearch("driveType", value);
  };
  const hangleColor = (value: string[]) => {
    dispatch(FiltersActions.setColors(value));
    manageAdvanceSearch("color", value);
  };
  const hangleGear = (value: string[]) => {
    dispatch(FiltersActions.setGears(value));
    manageAdvanceSearch("gear", value);
  };

  const handleUseInfo = (value: string[]) => {
    dispatch(FiltersActions.setUseInfos(value));
    manageAdvanceSearch("useInfo", value);
  };

  return (
    <ContainerStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.useInfo.label")}</SelectTitle>
        <SelectStyledMulty
          options={[]}
          value={selectedUseInfo}
          // onSelect={handleModel}
          onChange={handleUseInfo}
          // onClear={handleClear}
          placeholder={t("addCar.useInfo.label")}
          mode="multiple"
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.brand.label")}</SelectTitle>
        <SelectStyled
          options={brands}
          value={selectedBrand?.searchName}
          onSelect={handleBrand}
          // onChange={handleChange}
          // onClear={handleClear}
          placeholder={t("addCar.brand.label")}
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.model.label")}</SelectTitle>
        <SelectStyled
          options={models}
          value={selectedModel?.searchName}
          onSelect={handleModel}
          // onChange={handleChange}
          // onClear={handleClear}
          placeholder={t("addCar.model.label")}
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>

      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.generation.label")}</SelectTitle>
        <Generations />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.price.label")}</SelectTitle>
        <FromToWrapperStyled>
          <InputNumberStyled
            value={String(selectedPrice?.from || "0")}
            onChange={handleChangePrice(FromToEnum.FROM)}
            step={100}
            min={0}
          />
          <InputNumberStyled
            value={selectedPrice?.to as unknown as string}
            onChange={handleChangePrice(FromToEnum.TO)}
            step={100}
            min={0}
          />
        </FromToWrapperStyled>
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.year.label")}</SelectTitle>
        <YearWrapperStyled>
          <FromToWrapperStyled>
            <YearPickerComp type={FromToEnum.FROM} /> <YearPickerComp type={FromToEnum.TO} />
          </FromToWrapperStyled>
        </YearWrapperStyled>
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.mileage.label")}</SelectTitle>
        <FromToWrapperStyled>
          <InputNumberStyled
            value={String(selectedMileage?.from || "0")}
            onChange={handleChangeMilage(FromToEnum.FROM)}
            min={0}
          />
          <InputNumberStyled
            value={selectedMileage?.to as unknown as string}
            onChange={handleChangeMilage(FromToEnum.TO)}
            min={0}
          />
        </FromToWrapperStyled>
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.power.label")}</SelectTitle>
        <FromToWrapperStyled>
          <InputNumberStyled
            value={String(selectedPower?.from || "0")}
            onChange={handleChangePower(FromToEnum.FROM)}
            min={0}
          />
          <InputNumberStyled
            value={selectedPower?.to as unknown as string}
            onChange={handleChangePower(FromToEnum.TO)}
            min={0}
          />
        </FromToWrapperStyled>
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.bodyType.label")}</SelectTitle>
        <SelectStyledMulty
          options={bodyTypes}
          value={selectedBodyType}
          onChange={handleBodyType}
          mode="multiple"
          size="large"
          placeholder={t("addCar.bodyType.label")}
          showSearch
          allowClear
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.fuel.label")}</SelectTitle>
        <SelectStyledMulty
          options={fuels}
          value={selectedFuel}
          // onSelect={handleModel}
          onChange={hangleFuel}
          // onClear={handleClear}
          placeholder={t("addCar.fuel.label")}
          mode="multiple"
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.driveType.label")}</SelectTitle>
        <SelectStyledMulty
          options={driveTypes}
          value={selectedDriveType}
          // onSelect={handleModel}
          onChange={hangleDriveType}
          // onClear={handleClear}
          placeholder={t("addCar.driveType.label")}
          mode="multiple"
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.gear.label")}</SelectTitle>
        <SelectStyledMulty
          options={gears}
          value={selectedGear}
          // onSelect={handleModel}
          onChange={hangleGear}
          // onClear={handleClear}
          placeholder={t("addCar.gear.label")}
          mode="multiple"
          showSearch
          allowClear
          size="large"
        />
      </SelectWrapperStyled>
      <SelectWrapperStyled>
        <SelectTitle>{t("addCar.gear.label")}</SelectTitle>
        <ColorPicker colors={colors} selectedColor={selectedColor} />
      </SelectWrapperStyled>
    </ContainerStyled>
  );
};

const ColorItemWrapperStyled = styled.div<{
  $isSelected: boolean;
  $value: string;
  $black?: boolean;
}>`
  border: 1px solid ${(props) => (props.$isSelected ? "var(--main-color)" : "rgb(219, 219, 219)")};
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  & svg {
    position: absolute;
    ${(props) => (props.$isSelected ? "display: block" : "display: none")};

    & path {
      ${(props) => (props.$black ? "fill: rgb(0,0,0)" : "fill: rgb(255,255,255)")};
    }
  }
`;

const ColorItemStyled = styled.div<{ $isSelected: boolean; $value: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${(props) => props.$value};
`;

const ColorPickerWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const colorsWhite = ["white", "yellow", "beige"];

const ColorPicker: React.FC<{ colors: AdditionalInfoPyaload[]; selectedColor?: string[] }> = ({
  colors,
  selectedColor = [],
}) => {
  return (
    <ColorPickerWrapperStyled>
      {colors.map((color, index) => (
        <ColorItemWrapperStyled
          key={color.searchName}
          $isSelected={selectedColor.includes(color.searchName)}
          $value={color.searchName}
          $black={colorsWhite.includes(color.searchName)}
        >
          <ColorItemStyled
            $isSelected={selectedColor.includes(color.searchName)}
            $value={color.searchName}
          />
          <svg
            width="15.569000"
            height="11.303711"
            viewBox="0 0 15.569 11.3037"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              id="check"
              d="M5.33 11.3L0 5.96L1.06 4.9L5.33 9.16L14.5 0L15.56 1.06L5.33 11.3Z"
              fill="#FAFAFA"
              fillOpacity="1.000000"
              fillRule="evenodd"
            />
          </svg>
        </ColorItemWrapperStyled>
      ))}
    </ColorPickerWrapperStyled>
  );
};

const GenItemWrapperStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const GenItemStyled = styled.div<{ $isSelected: boolean }>`
  border: 1px solid ${(props) => (props.$isSelected ? "var(--main-color)" : "rgb(219, 219, 219)")};
  background: ${(props) => (props.$isSelected ? "var(--main-color)" : "transparent")};
  color: ${(props) => (props.$isSelected ? "rgb(255,255,255)" : "rgb(108, 108, 108)")};
  border-radius: 8px;
  cursor: pointer;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
`;

const GenWrapperStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const GenContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const GenLabelStyled = styled.div`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  padding: 16px;
  & span {
    color: var(--main-color);
  }
`;

const GenItemButtonStyled = styled(Button)`
  border: 1px solid rgb(219, 219, 219);
  border-radius: 8px;
  padding: 16px;
  color: rgb(61, 61, 61);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
`;

export const Generations = React.memo(() => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [, setSearchParams] = useSearchParams();
  const generations = useAppSelector((state) => state.filters.generations);
  const selectedGenerations = useAppSelector((state) => state.filters.selectedData?.generations);
  const genList = useMemo(() => {
    return [...(selectedGenerations || []), ...generations].reduce(
      (acc: SelectedGeneration[], item: SelectedGeneration) => {
        if (acc.find((accItem) => accItem._id === item._id)) {
          return acc;
        } else {
          return [...acc, item];
        }
      },
      [],
    );
  }, [selectedGenerations, generations]);
  console.log("generations", generations);
  console.log("selectedGenerations", selectedGenerations);

  const handleModification = (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => {
    console.log("gen", gen);
    console.log("selectedGenerations", selectedGenerations);
    console.log("generations", generations);
    const isSelectedGen = selectedGenerations?.find((item) => item._id === gen._id);
    if (isSelectedGen) {
      const nextGen = selectedGenerations?.map((item) => {
        if (item._id === gen._id) {
          return {
            ...gen,
            modifications: gen.options.map((modItem) => {
              if (modItem._id === mod._id) {
                return {
                  ...modItem,
                  isSelected: !modItem.isSelected,
                };
              }
              return modItem;
            }),
          };
        }
        return item;
      });
      const res = createGenSearch(nextGen || []);
      dispatch(FiltersActions.setGenerationSearch({ nextGen: nextGen || [] }));

      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    } else {
      const nextMod = gen.options.map((item) => {
        if (item._id === mod._id) {
          return {
            ...mod,
            isSelected: !mod.isSelected,
          };
        }
        return item;
      });
      const res = createGenSearch([...(selectedGenerations || []), { ...gen, options: nextMod }]);
      dispatch(
        FiltersActions.setGenerationSearch({
          nextGen: [...(selectedGenerations || []), { ...gen, options: nextMod }],
        }),
      );
      setSearchParams((prev) => {
        prev.set("generation", res);
        return prev;
      });
    }
  };

  return (
    <GenWrapperStyled>
      {genList.map((gen) => {
        return (
          <GenContainerStyled key={gen._id}>
            <GenLabelStyled>
              {gen.name}:{" "}
              <span>
                {gen.yearStart}-{gen.yearEnd || t("now")}
              </span>
            </GenLabelStyled>
            <GenItemWrapperStyled>
              <GenItem gen={gen} key={gen._id} onModification={handleModification} />
            </GenItemWrapperStyled>
          </GenContainerStyled>
        );
      })}
    </GenWrapperStyled>
  );
});

export const GenItem: React.FC<{
  gen: SelectedGeneration;
  onModification: (
    gen: SelectedGeneration,
    mod: Modification & {
      isSelected?: boolean | undefined;
    },
  ) => void;
}> = React.memo(({ gen, onModification }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const selectedMods = useMemo(() => {
    return gen.options.filter((mod) => mod.isSelected);
  }, [gen]);

  const handleOpen = () => {
    setIsOpen((prev) => !prev);
  };

  const handleGeneration =
    (
      gen: SelectedGeneration,
      mod: Modification & {
        isSelected?: boolean | undefined;
      },
    ) =>
    () => {
      onModification(gen, mod);
    };
  return (
    <>
      {isOpen ? (
        <>
          {gen.options.map((mod) => (
            <GenItemStyled
              key={mod._id}
              $isSelected={!!mod.isSelected}
              onClick={handleGeneration(gen, mod)}
            >
              {mod.name}
            </GenItemStyled>
          ))}
        </>
      ) : (
        selectedMods.map((mod) => (
          <GenItemStyled
            key={mod._id}
            $isSelected={!!mod.isSelected}
            onClick={handleGeneration(gen, mod)}
          >
            {mod.name}
          </GenItemStyled>
        ))
      )}

      <GenItemButtonStyled onClick={handleOpen}>
        {isOpen ? t("close") : t("open")}
      </GenItemButtonStyled>
    </>
  );
});
