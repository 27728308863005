import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  LoginThunk,
  ChangePasswordThunk,
  getCurrentUser,
  manageFavorite,
  VerifyPhoneThunk,
  RegisterThunk,
  ResetPasswordThunk,
  updateSettings,
  updateUserPhoto,
} from "./auth.thunks";
import { CarSubmitThunk } from "../../car/redux/car.thunks";
import { AuthOpenState } from "../types";

export interface AuthState {
  name: string;
  phone: string;
  isLogin: boolean;
  loading: boolean;
  isPending: boolean;
  _id?: string;
  favorite: string[];
  isPhoneVerified: boolean;
  isAuthOpen: boolean | AuthOpenState;
  isResetOpen: boolean;
  loginError?: string;
  registerError?: Record<string, string>;
  resetErrors?: Record<string, string>;
  photo?: string;
  isPhotoApproved?: boolean;
}

const initialState: AuthState = {
  name: "",
  phone: "",
  isLogin: false,
  loading: true,
  isPending: false,
  favorite: [],
  isPhoneVerified: false,
  isAuthOpen: false,
  isResetOpen: false,
  photo: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logOut: (state) => {
      state.isLogin = false;
      state._id = undefined;
      state.loading = false;
      state.phone = "";
      state.name = "";
      state.isPhoneVerified = false;
    },
    openAuth: (state, action: PayloadAction<AuthOpenState>) => {
      state.isAuthOpen = action.payload;
    },
    toggleAuth: (state, action: PayloadAction<AuthOpenState>) => {
      state.isAuthOpen = action.payload;
      state.loginError = undefined;
      state.registerError = undefined;
    },
    closeAuth: (state) => {
      state.isAuthOpen = false;
      state.loginError = undefined;
      state.registerError = undefined;
    },
    openReset: (state) => {
      state.isResetOpen = true;
    },
    closeReset: (state) => {
      state.isResetOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.pending, (state, action) => {
        console.log("action Pending", action);
        state.isPending = true;
        state.isLogin = false;
      })
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.photo = action.payload.photo;
        state.isPhotoApproved = action.payload.isPhotoApproved;
        state.isResetOpen = false;
        state.isAuthOpen = false;
        state.loginError = undefined;
        state.registerError = undefined;
      })
      .addCase(LoginThunk.rejected, (state, action) => {
        state.isLogin = false;
        state.isPending = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
        state.loginError = action.payload?.errorMessage;
      })
      .addCase(RegisterThunk.pending, (state, action) => {
        state.isPending = true;
        state.isLogin = false;
      })
      .addCase(RegisterThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.photo = action.payload.photo;
        state.isPhotoApproved = action.payload.isPhotoApproved;
        state.isResetOpen = false;
        state.isAuthOpen = false;
        state.registerError = undefined;
      })
      .addCase(RegisterThunk.rejected, (state, action) => {
        console.log("action", action.payload);
        state.isLogin = false;
        state.isPending = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
        state.registerError = action.payload;
      })
      .addCase(ResetPasswordThunk.pending, (state, action) => {
        state.isPending = true;
        state.isLogin = false;
      })
      .addCase(ResetPasswordThunk.fulfilled, (state, action) => {
        state.isPending = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.photo = action.payload.photo;
        state.isPhotoApproved = action.payload.isPhotoApproved;
        state.isResetOpen = false;
        state.isAuthOpen = false;
        state.resetErrors = undefined;
      })
      .addCase(ResetPasswordThunk.rejected, (state, action) => {
        console.log("action", action.payload);
        state.isLogin = false;
        state.isPending = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
        state.resetErrors = action.payload;
      })

      .addCase(getCurrentUser.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.loading = false;
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.photo = action.payload.photo;
        state.isPhotoApproved = action.payload.isPhotoApproved;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.isLogin = false;
        state.loading = false;
        state._id = undefined;
        state.phone = "";
        state.name = "";
      })
      .addCase(VerifyPhoneThunk.pending, (state, action) => {
        state.isPending = true;
      })
      .addCase(VerifyPhoneThunk.fulfilled, (state, action) => {
        state.isPhoneVerified = action.payload.isPhoneVerified;
        state.isPending = false;
      })
      .addCase(VerifyPhoneThunk.rejected, (state, action) => {
        state.isPending = false;
      })
      .addCase(manageFavorite.fulfilled, (state, action) => {
        state.favorite = action.payload.favorite;
      })
      .addCase(CarSubmitThunk.fulfilled, (state, action) => {
        if (action.payload?.user) {
          state.isLogin = true;
          state.phone = action.payload.user.phone;
          state.name = action.payload.user.name;
          state._id = action.payload.user._id;
          state.favorite = action.payload.user.favorite;
        }
      })
      .addCase(ChangePasswordThunk.fulfilled, (state, action) => {
        state.isLogin = true;
        state.phone = action.payload.phone;
        state.name = action.payload.name;
        state._id = action.payload._id;
        state.favorite = action.payload.favorite;
        state.isPhoneVerified = action.payload.isPhoneVerified;
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        state.name = action.payload.name;
      })
      .addCase(updateUserPhoto.fulfilled, (state, action) => {
        state.photo = action.payload.photo;
        state.isPhotoApproved = action.payload.isPhotoApproved;
      });
  },
});

export const AuthActions = authSlice.actions;

export default authSlice.reducer;
