import { Pagination, Select } from "antd";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { SearchCart } from "./searchCart";
import axios from "../../../helpers/interseptor";
import { useAppSelector } from "../../../helpers/store.hook";
import i18next from "../../../i18n/config";
import { CarRoutesPath } from "../../../routes";

const ContainerStyled = styled.div`
  max-width: 1222px;
`;

const ListStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
  justify-content: flex-start;
`;

const OrderWrapperStyled = styled.div`
  padding-bottom: 10px;
  display: flex;
  justify-content: flex-end;
`;
const OrderFilterItemStyled = styled.div<{ $Width?: number }>`
  min-width: ${(props) => props?.$Width || "250"}px;
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const OrderSelectStyled = styled(Select)<{ onChange: (value: string) => void }>`
  width: 100%;
  margin-left: 14px;
  color: rgb(46, 46, 46);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0;
  height: 40px;
`;

const orderOptions = [
  { value: "d", label: i18next.t("order.default") },
  { value: "p-l-t", label: i18next.t("order.price-low-top") },
  { value: "p-t-l", label: i18next.t("order.price-top-low") },
  { value: "y-l-t", label: i18next.t("order.year-low-top") },
  { value: "y-t-l", label: i18next.t("order.year-top-low") },
  { value: "m-l-t", label: i18next.t("order.mileage-low-top") },
  { value: "m-t-l", label: i18next.t("order.mileage-top-low") },
  { value: "publication", label: i18next.t("order.publication-date") },
];

const periodOptions = [
  { value: "all", label: i18next.t("order.all") },
  { value: "12-h", label: i18next.t("order.last-12-h") },
  { value: "day", label: i18next.t("order.last-day") },
  { value: "2-d", label: i18next.t("order.last-2-days") },
  { value: "week", label: i18next.t("order.last-week") },
  { value: "2-w", label: i18next.t("order.last-2-week") },
  { value: "month", label: i18next.t("order.last-month") },
];

export const SearchList = () => {
  const sort = useAppSelector((state) => state.filters.sort);
  const period = useAppSelector((state) => state.filters.period);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalCars, setTotalCars] = useState(1);
  const [cars, setCars] = useState<
    {
      VIN: string;
      brand: string;
      color: string;
      createdAt: string;
      engin: string;
      features: any[];
      fuel: string;
      generation: string;
      mileage: number;
      model: string;
      photo: string;
      transmission: string;
      updatedAt: string;
      year: number;
      _id: string;
    }[]
  >([]);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get<any>(`search/cars?${searchParams}`)
      .then((res) => res.data)
      .then((data) => {
        setCars(data.cars);
        setTotalCars(data.totalCars);
        setPage(data.page);
        setLimit(data.limit);
      });
  }, [searchParams]);

  const handleNext = (page: number) => {
    setSearchParams((prev) => {
      prev.set("page", String(page));
      return prev;
    });
  };

  const handleShowSize = (current: number, size: number) => {
    setSearchParams((prev) => {
      prev.set("limit", String(size));
      return prev;
    });
  };

  const handleSort = (value: string) => {
    setSearchParams((prev) => {
      prev.set("sort", value);
      return prev;
    });
  };
  const handlePeriod = (value: string) => {
    setSearchParams((prev) => {
      prev.set("period", value);
      return prev;
    });
  };

  return (
    <ContainerStyled>
      <OrderWrapperStyled>
        <OrderFilterItemStyled $Width={290}>
          <OrderSelectStyled onChange={handleSort} value={sort} options={orderOptions} />
        </OrderFilterItemStyled>
        <OrderFilterItemStyled>
          <OrderSelectStyled onChange={handlePeriod} value={period} options={periodOptions} />
        </OrderFilterItemStyled>
      </OrderWrapperStyled>
      <ListStyled>
        {cars.map((car) => (
          <SearchCart
            {...car}
            key={car._id}
            _id={car._id}
            link={`${CarRoutesPath.car}/${car._id}`}
          />
        ))}
      </ListStyled>
      <Pagination
        onChange={handleNext}
        total={totalCars}
        current={page}
        defaultPageSize={limit}
        onShowSizeChange={handleShowSize}
      />
    </ContainerStyled>
  );
};
