import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { getBrandsThunk, getGenerationsThunk, getModelsThunk } from "./filters.thunks";
import i18next from "../../../i18n/config";
import { AdditionalInfoPyaload } from "../../car/CarTypes";
import {
  CarModel,
  CardSearchEnum,
  FromToEnum,
  SelectedGeneration,
  SearchState,
  AdditionalInfoSelectedKeys,
} from "../filters.types";

const initialState: SearchState = {
  brands: [],
  models: [],
  generations: [],
  cardSearchType: CardSearchEnum.BRAND,
  // year: {},
  // price: {
  //   from: 1000,
  //   to: 20000,
  // },
  selectedData: {
    price: {
      from: 10,
      to: 110000,
    },
    mileage: {
      from: 0,
      to: 300000,
    },
  },
  sort: "d",
  period: "all",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setModel: (state, action: PayloadAction<CarModel>) => {
      state.selectedData.model = action.payload;
      state.selectedData.generations = [];
      state.generations = [];
    },
    clearModel: (state) => {
      state.cardSearchType = CardSearchEnum.MODEL;
      state.generations = [];
      state.selectedData.model = undefined;
      state.selectedData.generations = [];
    },

    clearBrand: (state) => {
      state.cardSearchType = CardSearchEnum.BRAND;
      state.models = [];
      state.selectedData.brand = undefined;
      state.selectedData.model = undefined;
      state.selectedData.generations = [];
    },
    clearGenerations: (state) => {
      state.selectedData.generations = [];
      state.cardSearchType = CardSearchEnum.GENERATION;
    },
    clearSelectedData: (state) => {
      state.selectedData = {};
    },
    setYearSlider: (state, action: PayloadAction<number[]>) => {
      const [from, to] = action.payload;
      state.selectedData.year = {
        from,
        to,
      };
    },
    setYear: (state, action: PayloadAction<{ type: FromToEnum; value?: number }>) => {
      if (state.selectedData.year) {
        state.selectedData.year[action.payload.type] = action.payload.value;
      } else {
        state.selectedData.year = {
          to: Number(process.env.REACT_APP_MAX_YEAR),
          [action.payload.type]: action.payload.value,
        };
      }
    },
    setPrice: (state, action: PayloadAction<{ type: FromToEnum; value?: number }>) => {
      if (state.selectedData.price) {
        state.selectedData.price[action.payload.type] = action.payload.value;
      } else {
        state.selectedData.price = {
          [action.payload.type]: action.payload.value,
        };
      }
    },
    setMileage: (state, action: PayloadAction<{ type: FromToEnum; value?: number }>) => {
      if (state.selectedData.mileage) {
        state.selectedData.mileage[action.payload.type] = action.payload.value;
      } else {
        state.selectedData.mileage = {
          [action.payload.type]: action.payload.value,
        };
      }
    },

    setSort: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    setPeriod: (state, action: PayloadAction<string>) => {
      state.period = action.payload;
    },
    setGenerationsMultyCard: (state, action: PayloadAction<SelectedGeneration>) => {
      const isSelected = state.selectedData.generations?.find(
        ({ _id }) => _id === action.payload._id,
      );
      if (isSelected) {
        state.selectedData.generations = state.selectedData.generations?.filter(
          ({ _id }) => _id !== action.payload._id,
        );
      } else {
        state.selectedData?.generations?.push(action.payload);
        state.selectedData?.generations?.sort((a, b) => a.yearStart - b.yearStart);
      }
    },

    onSelectGeneration: (state, action: PayloadAction<SelectedGeneration>) => {
      state.cardSearchType = CardSearchEnum.MODIFICATION;
      if (state.selectedData.generations?.length) {
        state.selectedData.generations.push(action.payload);
      } else {
        state.selectedData.generations = [action.payload];
      }
    },
    onDeselectGeneration: (state, action: PayloadAction<SelectedGeneration>) => {
      state.selectedData.generations = state.selectedData.generations?.filter(
        ({ _id }) => _id !== action.payload._id,
      );
      if (state.selectedData.generations?.length === 0) {
        state.cardSearchType = CardSearchEnum.GENERATION;
      }
    },

    setSearchCartType: (state, action: PayloadAction<CardSearchEnum>) => {
      state.cardSearchType = action.payload;
    },

    setGenerationSearch: (state, action: PayloadAction<{ nextGen: SelectedGeneration[] }>) => {
      state.selectedData.generations = action.payload.nextGen;
    },

    setMultyModification: (state, action: PayloadAction<SelectedGeneration>) => {
      const isSelected = action.payload.options?.every((mod) => mod.isSelected);
      if (isSelected) {
        state.selectedData.generations = state.selectedData.generations?.map((gen) => {
          if (gen._id === action.payload._id) {
            gen.options = gen.options?.map((mod) => {
              mod.isSelected = false;
              return mod;
            });
          }
          return gen;
        });
      } else {
        state.selectedData.generations = state.selectedData.generations?.map((gen) => {
          if (gen._id === action.payload._id) {
            gen.options = gen.options?.map((mod) => {
              mod.isSelected = true;
              return mod;
            });
          }
          return gen;
        });
      }
    },

    setModification: (
      state,
      action: PayloadAction<{ modification: { _id: string }; parentId: string }>,
    ) => {
      const parrent = state.selectedData.generations?.find(
        ({ _id }) => _id === action.payload.parentId,
      );

      if (parrent) {
        const modification = parrent.options?.find(
          ({ _id }) => _id === action.payload.modification._id,
        );
        if (modification) {
          modification.isSelected = !modification?.isSelected;
        }
      }
    },

    setGenerations: (state, action: PayloadAction<{ [key: string]: string[] }>) => {
      const keys = Object.keys(action.payload);
      const selectedGenerations = state.generations
        .filter(({ searchName }) => keys.includes(searchName))
        .map((item) => {
          return {
            ...item,
            options: item.options?.map((mod) => {
              const isSelected = action.payload[item.searchName].includes(mod.searchName);
              return { ...mod, isSelected };
            }),
          };
        });
      if (selectedGenerations.length) {
        state.selectedData.generations = selectedGenerations;
      }
    },

    setPowers: (state, action: PayloadAction<{ type: FromToEnum; value?: number }>) => {
      if (state.selectedData.power) {
        state.selectedData.power[action.payload.type] = action.payload.value;
      } else {
        state.selectedData.power = {
          to: 1500,
          [action.payload.type]: action.payload.value,
        };
      }
    },

    setPower: (state, action: PayloadAction<number[]>) => {
      const [from, to] = action.payload;
      state.selectedData.power = {
        from,
        to,
      };
    },
    setPriceSlider: (state, action: PayloadAction<number[]>) => {
      const [from, to] = action.payload;

      state.selectedData.price = {
        from,
        to,
      };
    },
    setMileageSlider: (state, action: PayloadAction<number[]>) => {
      const [from, to] = action.payload;
      state.selectedData.mileage = {
        from,
        to,
      };
    },
    setDriveTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedData.driveType = action.payload;
    },
    setDriveType: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.driveType?.includes(action.payload);
      if (isExist) {
        state.selectedData.driveType = state.selectedData.driveType?.filter(
          (driveType) => driveType !== action.payload,
        );
      } else {
        state.selectedData.driveType = [...(state.selectedData.driveType || []), action.payload];
      }
    },

    setAdditionalInfo: (
      state,
      action: PayloadAction<{ type: AdditionalInfoSelectedKeys; value: string }>,
    ) => {
      const { type, value } = action.payload;
      const isExist = state.selectedData[type]?.includes(value);
      if (isExist) {
        state.selectedData[type] = state.selectedData[type]?.filter((option) => option !== value);
      } else {
        state.selectedData[type] = [...(state.selectedData[type] || []), value];
      }
    },

    setRegions: (state, action: PayloadAction<string[]>) => {
      state.selectedData.region = action.payload;
    },

    setBodyTypes: (state, action: PayloadAction<string[]>) => {
      state.selectedData.bodyType = action.payload;
    },
    setBodyType: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.bodyType?.includes(action.payload);
      if (isExist) {
        state.selectedData.bodyType = state.selectedData.bodyType?.filter(
          (bodyType) => bodyType !== action.payload,
        );
      } else {
        state.selectedData.bodyType = [...(state.selectedData.bodyType || []), action.payload];
      }
    },
    setColors: (state, action: PayloadAction<string[]>) => {
      state.selectedData.color = action.payload;
    },
    setColor: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.color?.includes(action.payload);

      if (isExist) {
        state.selectedData.color = state.selectedData.color?.filter(
          (color) => color !== action.payload,
        );
      } else {
        state.selectedData.color = [...(state.selectedData.color || []), action.payload];
      }
    },
    setGears: (state, action: PayloadAction<string[]>) => {
      state.selectedData.gear = action.payload;
    },
    setGear: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.gear?.includes(action.payload);

      if (isExist) {
        state.selectedData.gear = state.selectedData.gear?.filter(
          (gear) => gear !== action.payload,
        );
      } else {
        state.selectedData.gear = [...(state.selectedData.gear || []), action.payload];
      }
    },
    setGearBulk: (state, action: PayloadAction<AdditionalInfoPyaload>) => {
      if (action.payload.options) {
        const isSelected = action.payload.options?.every((item) =>
          state.selectedData.gear?.includes(item.value),
        );
        if (isSelected) {
          state.selectedData.gear = state.selectedData.gear?.filter(
            (item) => !action.payload.options?.find(({ value }) => value === item),
          );
        } else {
          const nextGear = new Set([
            ...(state.selectedData.gear || []),
            ...(action.payload.options?.map((item) => item.value) || []),
          ]);
          state.selectedData.gear = Array.from(nextGear);
        }
      } else {
        const isExist = state.selectedData.gear?.includes(action.payload.value);
        if (isExist) {
          state.selectedData.gear = state.selectedData.gear?.filter(
            (gear) => gear !== action.payload.value,
          );
        } else {
          state.selectedData.gear = [...(state.selectedData.gear || []), action.payload.value];
        }
      }
    },
    setFuels: (state, action: PayloadAction<string[]>) => {
      state.selectedData.fuel = action.payload;
    },

    setFuel: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.fuel?.includes(action.payload);

      if (isExist) {
        state.selectedData.fuel = state.selectedData.fuel?.filter(
          (fuel) => fuel !== action.payload,
        );
      } else {
        state.selectedData.fuel = [...(state.selectedData.fuel || []), action.payload];
      }
    },
    setFuelsBulk: (state, action: PayloadAction<AdditionalInfoPyaload>) => {
      if (action.payload.options) {
        const isSelected = action.payload.options?.every((item) =>
          state.selectedData.fuel?.includes(item.value),
        );
        if (isSelected) {
          state.selectedData.fuel = state.selectedData.fuel?.filter(
            (item) => !action.payload.options?.find(({ value }) => value === item),
          );
        } else {
          const nextFuel = new Set([
            ...(state.selectedData.fuel || []),
            ...(action.payload.options?.map((item) => item.value) || []),
          ]);
          state.selectedData.fuel = Array.from(nextFuel);
        }
      } else {
        const isExist = state.selectedData.fuel?.includes(action.payload.value);
        if (isExist) {
          state.selectedData.fuel = state.selectedData.fuel?.filter(
            (fuel) => fuel !== action.payload.value,
          );
        } else {
          state.selectedData.fuel = [...(state.selectedData.fuel || []), action.payload.value];
        }
      }
    },
    setLights: (state, action: PayloadAction<string[]>) => {
      state.selectedData.light = action.payload;
    },
    setLight: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.light?.includes(action.payload);

      if (isExist) {
        state.selectedData.light = state.selectedData.light?.filter(
          (light) => light !== action.payload,
        );
      } else {
        state.selectedData.light = [...(state.selectedData.light || []), action.payload];
      }
    },

    setOptics: (state, action: PayloadAction<string[]>) => {
      state.selectedData.optic = action.payload;
    },
    setOptic: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.optic?.includes(action.payload);

      if (isExist) {
        state.selectedData.optic = state.selectedData.optic?.filter(
          (optic) => optic !== action.payload,
        );
      } else {
        state.selectedData.optic = [...(state.selectedData.optic || []), action.payload];
      }
    },

    setSalons: (state, action: PayloadAction<string[]>) => {
      state.selectedData.salon = action.payload;
    },
    setSalon: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.salon?.includes(action.payload);

      if (isExist) {
        state.selectedData.salon = state.selectedData.salon?.filter(
          (salon) => salon !== action.payload,
        );
      } else {
        state.selectedData.salon = [...(state.selectedData.salon || []), action.payload];
      }
    },

    setSecurities: (state, action: PayloadAction<string[]>) => {
      state.selectedData.security = action.payload;
    },
    setSecurity: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.security?.includes(action.payload);

      if (isExist) {
        state.selectedData.security = state.selectedData.security?.filter(
          (sec) => sec !== action.payload,
        );
      } else {
        state.selectedData.security = [...(state.selectedData.security || []), action.payload];
      }
    },
    setAirBag: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.airbag?.includes(action.payload);

      if (isExist) {
        state.selectedData.airbag = state.selectedData.airbag?.filter(
          (airbag) => airbag !== action.payload,
        );
      } else {
        state.selectedData.airbag = [...(state.selectedData.airbag || []), action.payload];
      }
    },
    setAirbags: (state, action: PayloadAction<string[]>) => {
      state.selectedData.airbag = action.payload;
    },

    setUseInfos: (state, action: PayloadAction<string[]>) => {
      state.selectedData.useInfo = action.payload;
    },
    setUseInfo: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.useInfo?.includes(action.payload);

      if (isExist) {
        state.selectedData.useInfo = state.selectedData.useInfo?.filter(
          (useInfo) => useInfo !== action.payload,
        );
      } else {
        state.selectedData.useInfo = [...(state.selectedData.useInfo || []), action.payload];
      }
    },
    setAfterAccident: (state, action: PayloadAction<boolean>) => {
      state.selectedData.afterAccident = action.payload;
    },

    setMultimedias: (state, action: PayloadAction<string[]>) => {
      state.selectedData.multimedia = action.payload;
    },
    setMultimedia: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.multimedia?.includes(action.payload);

      if (isExist) {
        state.selectedData.multimedia = state.selectedData.multimedia?.filter(
          (multimedia) => multimedia !== action.payload,
        );
      } else {
        state.selectedData.multimedia = [...(state.selectedData.multimedia || []), action.payload];
      }
    },
    setOther: (state, action: PayloadAction<string>) => {
      const isExist = state.selectedData.other?.includes(action.payload);

      if (isExist) {
        state.selectedData.other = state.selectedData.other?.filter(
          (other) => other !== action.payload,
        );
      } else {
        state.selectedData.other = [...(state.selectedData.other || []), action.payload];
      }
    },
    setOthers: (state, action: PayloadAction<string[]>) => {
      state.selectedData.other = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBrandsThunk.fulfilled, (state, action) => {
        state.brands = action.payload.map((brand) => ({
          ...brand,
          label: brand.shortName,
          value: brand.searchName,
        }));
        state.models = [];
      })
      .addCase(getModelsThunk.pending, (state, action) => {
        state.cardSearchType = CardSearchEnum.MODEL;
        state.models = [];
        state.generations = [];

        state.selectedData.brand = action.meta.arg;
        state.selectedData.generations = [];
        state.selectedData.model = undefined;
      })
      .addCase(getModelsThunk.fulfilled, (state, action) => {
        state.cardSearchType = CardSearchEnum.MODEL;
        state.models = action.payload.map(({ series, ...model }) => {
          if (series?.length) {
            return {
              ...model,
              title: model.name,
              label: model.name,
              _id: model._id,
              options: [
                {
                  brand: model.brand,
                  name: model.name,
                  label: model.name,
                  value: model.searchName,
                  _id: model._id,
                  searchName: model.searchName,
                  image: model.image,
                  isParent: true,
                },
                ...series.map((series) => ({
                  ...series,
                  label: series.name,
                  value: series.searchName,
                  parentId: model._id,
                })),
              ],
            };
          }
          return {
            ...model,
            label: model.name,
            value: model.searchName,
          };
        });
      })
      .addCase(getGenerationsThunk.pending, (state, action) => {
        state.selectedData.model = action.meta.arg;
      })
      .addCase(getGenerationsThunk.fulfilled, (state, action) => {
        state.cardSearchType = CardSearchEnum.GENERATION;
        state.generations = action.payload.map((gen) => ({
          ...gen,
          label: `${gen.name}: ${gen.yearStart}-${gen?.yearEnd || i18next.t("now")}`,
          value: `${gen.name}: ${gen.yearStart}-${gen?.yearEnd || i18next.t("now")}`,
        }));

        state.selectedData.generations = [];
      });
  },
});

export const FiltersActions = filtersSlice.actions;

export default filtersSlice.reducer;

//TODO move to reselect
