import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import { NavigateFunction } from "react-router-dom";

import { AdditionalInfoDTOKeys } from "../../../constants/AdditionalInfo";
import axios from "../../../helpers/interseptor";
import i18next from "../../../i18n/config";
import { CarRoutesPath } from "../../../routes";
import { AppDispatch, RootState } from "../../../store";
import { setTokenLocal } from "../../auth/utils/tokenLocal";
import { AdditionalInfoPyaloadDTO, Brand, CarGenerationDTO, CarModelDTO } from "../CarTypes";

interface ValidationErrors {
  errorMessage: string;
}

export const CarGetBrandsThunk = createAsyncThunk<
  Brand[],
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("carGetBrandsThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get<Brand[]>("search/brands"); //TODO move to common

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const CarSelectBrandThunk = createAsyncThunk<
  CarModelDTO[],
  { value: string },
  {
    rejectValue: ValidationErrors;
  }
>("carSelectBrandThunk", async ({ value }, { rejectWithValue }) => {
  try {
    const response = await axios.get<CarModelDTO[]>(`search/models/${value}`);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const CarSelectGenerationsThunk = createAsyncThunk<
  CarGenerationDTO[],
  {
    value: string;
    searchName: string;
    parentModel?: string;
    name: string;
  },
  {
    rejectValue: ValidationErrors;
  }
>("carSelectGenerationsThunk", async ({ searchName }, { rejectWithValue }) => {
  try {
    const response = await axios.get<CarGenerationDTO[]>(`search/generations/${searchName}`);
    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const CarGetAdditionalInfoThunk = createAsyncThunk<
  { _id: AdditionalInfoDTOKeys; values: AdditionalInfoPyaloadDTO[] }[],
  undefined,
  {
    rejectValue: ValidationErrors;
  }
>("carGetAdditionalInfoThunk", async (args, { rejectWithValue }) => {
  try {
    const response = await axios.get<
      {
        _id: AdditionalInfoDTOKeys;
        values: AdditionalInfoPyaloadDTO[];
      }[]
    >("search/additional-info");

    if (response.status < 200 || response.status >= 300) {
      return rejectWithValue({ errorMessage: "" });
    }

    return response.data;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return rejectWithValue({
        errorMessage: e.response?.data.message || "Something went wrong",
      });
    }
    console.log(e);
    return rejectWithValue({ errorMessage: "Something went wrong" });
  }
});

export const CarSubmitThunk = createAsyncThunk<
  | { user: { phone: string; name: string; _id: string; favorite: string[] } | null; newCar: any }
  | undefined,
  {
    edit?: boolean;
    _id?: string;
    isAdmin?: boolean;
    phone?: string;
    navigate: NavigateFunction;
  },
  {
    rejectValue: ValidationErrors;
    state: RootState;
  }
>(
  "carSubmitThunk",
  async ({ edit, _id, isAdmin, phone, navigate }, { rejectWithValue, getState }) => {
    try {
      const { photo, ...data } = getState().car.selectedData;

      let result: any;

      notification.info({ message: i18next.t("notification.addcar.add"), key: "info" });
      if (edit) {
        if (isAdmin) {
          console.log("data As Admin", data);
        } else {
          await axios.post<any[]>(`car/edit/${_id}`, data);
        }
      } else {
        result = await axios.post<{ newCar: { _id: string }; user: any }>("car/add", {
          ...data,
          phone: phone,
        });
      }
      if (result?.data.user) {
        setTokenLocal(result.data.user.tokens);
      }

      const payloadImage = new FormData();
      for (const image of photo) {
        const blob = await fetch(image.image).then((r) => r.blob());
        const imageType = blob.type.split("/")[1];
        payloadImage.append("image", blob, `${data.vin}-${image._id}.${imageType}`);
      }
      await axios
        .post<any[]>(`car/add-image/${data.vin}`, payloadImage)
        .then((res) => {
          console.log("resImage", res);
          // rework URL
        })
        .catch((e) => {
          console.log("e", e);
        });
      notification.success({ message: i18next.t("notification.addcar.photo-done"), key: "photo" });

      navigate(`${CarRoutesPath.car}/${result?.data.newCar._id}`);

      return result?.data;
    } catch (e) {
      console.log("e", e);
      notification.destroy("info");
      notification.destroy("photo");

      if (axios.isAxiosError(e)) {
        notification.error({
          message: i18next.t(e.response?.data?.message || "Something went wrong", {
            maxCarCount: e.response?.data?.maxCarCount || 3,
          }),
        });
        return rejectWithValue({
          errorMessage: e.response?.data.message || "Something went wrong",
        });
      }
      return rejectWithValue({ errorMessage: "Something went wrong" });
    }
  },
);

// export const getCarByIdThunk = createAsyncThunk<
//   any,
//   { _id: string },
//   {
//     rejectValue: ValidationErrors;
//     dispatch: AppDispatch;
//   }
// >("getCarByIdThunk", async ({ _id }, { rejectWithValue, dispatch }) => {
//   try {
//     const response = await axios.get<any>(`/car/${_id}`);

//     dispatch(
//       CarSelectGenerationsThunk({
//         value: response.data.model,
//         searchName: response.data.model,
//       }),
//     );
//     dispatch(CarSelectBrandThunk({ value: response.data.brand }));
//     return response.data;
//   } catch (e) {
//     if (axios.isAxiosError(e)) {
//       return rejectWithValue({
//         errorMessage: e.response?.data.message || "Something went wrong",
//       });
//     }
//     return rejectWithValue({ errorMessage: "Something went wrong" });
//   }
// });
